import { RootState } from 'aldgpt_design/app/store/store';
import { GenParams } from '../types/types';

import { getCanvasData } from 'aldgpt_design/features/canvas/util/getCanvasData';
import { selectImagesById } from 'aldgpt_design/features/gallery/store/imagesSlice';

export const buildTextToImageParam = async (
  state: RootState
): Promise<GenParams> => {
  const {
    positivePrompt,
    negativePrompt,
    generateType,
    iterations,
    width,
    height,
    style,
  } = state.generation;

  const {
    structureImageName,
    isStructureEnabled,
    styleImageName,
    isStyleEnabled,
    structureType,
  } = state.advancedParam;

  let refStructure: string | null = '';
  let refStructureType: string | null = '';
  if (isStructureEnabled && structureImageName) {
    const imageDTO = selectImagesById(state, structureImageName ?? '');
    refStructure = imageDTO?.image_url as string;
    //refStructure = await db.getImageUrl(structureImageName) as string;
    refStructureType = structureType;
  }

  let refSyle: string | null = '';
  if (isStyleEnabled && styleImageName) {
    const imageDTO = selectImagesById(state, styleImageName ?? '');
    refSyle = imageDTO?.image_url as string;
    //refSyle = await db.getImageUrl(styleImageName) as string;
  }

  // const canvasBlobsAndImageData = await getCanvasData(state);
  // //const { baseBlob, baseImageData, maskBlob, maskImageData } =
  // canvasBlobsAndImageData;

  const graph: GenParams = {
    type: 'TextToImage',
    prompt: positivePrompt,
    negative_prompt: negativePrompt,
    generateType: generateType,
    n_iter: iterations,
    width: width,
    height: height,
    style: style,
    refStructure: refStructure,
    refStructureType: refStructureType,
    refStyle: refSyle,
    baseImage: '',
    maskImage: '',
    structureImageName:structureImageName,
    styleImageName:styleImageName
  };

  

  return graph;
};