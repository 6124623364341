import queryString from 'query-string';
import { createAsyncThunk } from '@reduxjs/toolkit';
//import { createAppAsyncThunk } from 'aldgpt_design/app/store/storeUtils';
import { selectImagesAll,imageUpserted, imageUpsertMany } from 'aldgpt_design/features/gallery/store/imagesSlice';
import { size } from 'lodash-es';
import { paths } from 'aldgpt_design/services/api/schema';
import { $client } from 'aldgpt_design/services/api/client';
import { RootState } from 'aldgpt_design/app/store/store';
import { Client4 } from 'aldgpt_design/common/util/client';
import { SaveContent } from 'aldgpt_design/app/types/content';
import { structureImageChanged } from 'aldgpt_design/features/advanced/store/slice';
import { useAppSelector } from 'aldgpt_design/app/store/storeHooks';
type GetImageUrlsArg =
  paths['/api/v1/images/{image_name}/urls']['get']['parameters']['path'];

type GetImageUrlsResponse =
  paths['/api/v1/images/{image_name}/urls']['get']['responses']['200']['content']['application/json'];

type GetImageUrlsThunkConfig = {
  rejectValue: {
    arg: GetImageUrlsArg;
    error: unknown;
  };
};
/**
 * Thunk to get image URLs
 */
export const imageUrlsReceived = createAsyncThunk<
  GetImageUrlsResponse,
  GetImageUrlsArg,
  GetImageUrlsThunkConfig
>('api/imageUrlsReceived', async (arg, { rejectWithValue }) => {
  const { image_name } = arg;
  const { get } = $client.get() as any;
  const { data, error, response } = await get(
    '/api/v1/images/{image_name}/urls',
    {
      params: {
        path: {
          image_name,
        },
      },
    }
  );

  if (error) {
    return rejectWithValue({ arg, error });
  }

  return data;
});

type GetImageMetadataArg =
  paths['/api/v1/images/{image_name}/metadata']['get']['parameters']['path'];

type GetImageMetadataResponse =
  paths['/api/v1/images/{image_name}/metadata']['get']['responses']['200']['content']['application/json'];

type GetImageMetadataThunkConfig = {
  rejectValue: {
    arg: GetImageMetadataArg;
    error: unknown;
  };
};

export const imageMetadataReceived = createAsyncThunk<
  GetImageMetadataResponse,
  GetImageMetadataArg,
  GetImageMetadataThunkConfig
>('api/imageMetadataReceived', async (arg, { rejectWithValue }) => {
  const { image_name } = arg;
  const { get } = $client.get() as any;
  const { data, error, response } = await get(
    '/api/v1/images/{image_name}/metadata',
    {
      params: {
        path: { image_name },
      },
    }
  );

  if (error) {
    return rejectWithValue({ arg, error });
  }

  return data;
});

type ControlNetAction = {
  type: 'SET_CONTROLNET_IMAGE';
  controlNetId: string;
};

type InitialImageAction = {
  type: 'SET_INITIAL_IMAGE';
};

type NodesAction = {
  type: 'SET_NODES_IMAGE';
  nodeId: string;
  fieldName: string;
};

type CanvasInitialImageAction = {
  type: 'SET_CANVAS_INITIAL_IMAGE';
};

type CanvasMergedAction = {
  type: 'TOAST_CANVAS_MERGED';
};

type CanvasSavedToGalleryAction = {
  type: 'TOAST_CANVAS_SAVED_TO_GALLERY';
};

type UploadedToastAction = {
  type: 'TOAST_UPLOADED';
};

type StructureAction = {
  type: 'SET_STRUCTURE_IMAGE';
};

type StyleAction = {
  type: 'SET_STYLE_IMAGE';
};

export type PostUploadAction =
  | ControlNetAction
  | InitialImageAction
  | NodesAction
  | CanvasInitialImageAction
  | CanvasMergedAction
  | CanvasSavedToGalleryAction
  | UploadedToastAction
  | StructureAction
  | StyleAction;

type UploadImageArg =
  paths['/api/v1/images/']['post']['parameters']['query'] & {
    file: File;
    // file: paths['/api/v1/images/']['post']['requestBody']['content']['multipart/form-data']['file'];
    postUploadAction?: PostUploadAction;
    imageType?: string
  };

type UploadImageResponse =
  paths['/api/v1/images/']['post']['responses']['201']['content']['application/json'];

type UploadImageThunkConfig = {
  rejectValue: {
    arg: UploadImageArg;
    error: unknown;
  };
};
/**
 * `ImagesService.uploadImage()` thunk
 */
export const imageUploaded = createAsyncThunk('api/imageUploaded', async (arg: UploadImageArg, { rejectWithValue }) => {
  const {
    postUploadAction,
    file,
    image_category,
    is_intermediate,
    session_id,
    imageType
  } = arg;
  
  const formData = new FormData();
  formData.append('file', file);
  try {
    const response = await fetch('https://images.aldgptai.com/upload', {
      method: 'POST',
      body: formData
    });


    if (!response.ok) {
      throw new Error(`Server responded with status code ${response.status}`);
    }
    const data = await response.json();
    if (data && data.file_name) {
      console.log('文件上传成功，文件名:', data.file_name);
      //const img_thumbnail = await generateThumbnailFromFile(file);
      const { width: image_width, height: image_height } = await getImageSizeFromFile(file);
      const revalue = {
        image_name: data.file_name,
        image_category: image_category,
        image_url: data.image_url,
        thumbnail_url: data.thumbnail_url,
        width: image_width,
        height: image_height,
        type: (imageType != 'Upload' && imageType != ''&& imageType != undefined) ? imageType : 'Upload',
      };
      const searchParam = new URLSearchParams(window.location.search);
      const userId = searchParam.get('userId') || "nuyi468a3prbiptn5f97uu4nie";
      
      // if(imageType == 'STRUCTURE_IMAGE' || imageType== 'STYLE_IMAGE'){
      //   return revalue;
      // }
      const saveParams = {
        image_url: data.image_url,
        ref_structure: '',
        ref_structure_type: '',
        ref_style: '',
        style: '',
        prompt: '',
        negative_prompt: '',
        width: image_width,
        height: image_height,
        generate_type: '',
        user_id: userId,
        thumbnail_url: data.thumbnail_url,
        type: 'Upload',
        image_name: data.file_name
      }
      const result = await Client4.saveContent(saveParams as SaveContent);
      result.image_name = result.id
      return result;
    } else {
      console.log('文件上传失败，错误信息:', data.error);
      return rejectWithValue({ arg, error: data.error });;
    }
  } catch (error) {
    console.error('File upload failed:', error);
    return rejectWithValue({ arg, error });
  }



  // const img_base64: string = await new Promise((resolve) => {
  //   let fileReader = new FileReader();
  //   fileReader.onload = (e) => resolve(fileReader.result as string);
  //   fileReader.readAsDataURL(file);
  // });

  // const img_thumbnail_base64 = await generateThumbnail(img_base64);
  // const { width: image_width, height: image_height } = await getImageSizeFromBase64(img_base64);
  // const data = {
  //   image_name: uuidv4(),
  //   image_category: image_category,
  //   thumbnail_url: img_thumbnail_base64,
  //   width: image_width,
  //   height: image_height,
  // };

  // db.images.add({
  //   image_name: data.image_name,
  //   image_url: img_base64 as string,
  //   thumbnail_url: img_thumbnail_base64 as string,
  //   image_category: data.image_category,
  //   width: data.width,
  //   height: data.height
  // })

  // const error = false;

  // if (error) {
  //   return rejectWithValue({ arg, error });
  // }

  // return data;

});

type DeleteImageArg =
  paths['/api/v1/images/{image_name}']['delete']['parameters']['path'];

type DeleteImageResponse =
  paths['/api/v1/images/{image_name}']['delete']['responses']['200']['content']['application/json'];

type DeleteImageThunkConfig = {
  rejectValue: {
    arg: DeleteImageArg;
    error: unknown;
  };
};
/**
 * `ImagesService.deleteImage()` thunk
 */
export const imageDeleted = createAsyncThunk<
  DeleteImageResponse,
  DeleteImageArg,
  DeleteImageThunkConfig
>('api/imageDeleted', async (arg, { rejectWithValue }) => {
  const { image_name } = arg;
  const { del } = $client.get() as any;
  const { data, error, response } = await del('/api/v1/images/{image_name}', {
    params: {
      path: {
        image_name,
      },
    },
  });

  if (error) {
    return rejectWithValue({ arg, error });
  }
});

type UpdateImageArg =
  paths['/api/v1/images/{image_name}']['patch']['requestBody']['content']['application/json'] &
  paths['/api/v1/images/{image_name}']['patch']['parameters']['path'];

type UpdateImageResponse =
  paths['/api/v1/images/{image_name}']['patch']['responses']['200']['content']['application/json'];

type UpdateImageThunkConfig = {
  rejectValue: {
    arg: UpdateImageArg;
    error: unknown;
  };
};
/**
 * `ImagesService.updateImage()` thunk
 */
export const imageUpdated = createAsyncThunk<
  UpdateImageResponse,
  UpdateImageArg,
  UpdateImageThunkConfig
>('api/imageUpdated', async (arg, { rejectWithValue }) => {
  const { image_name, image_category, is_intermediate, session_id } = arg;
  const { patch } = $client.get() as any;
  const { data, error, response } = await patch('/api/v1/images/{image_name}', {
    params: {
      path: {
        image_name,
      },
    },
    body: {
      image_category,
      is_intermediate,
      session_id,
    },
  });

  if (error) {
    return rejectWithValue({ arg, error });
  }

  return data;
});

export const IMAGES_PER_PAGE = 20;

const DEFAULT_IMAGES_LISTED_ARG = {
  limit: IMAGES_PER_PAGE,
};

type ListImagesArg = NonNullable<
  paths['/api/v1/images/']['get']['parameters']['query']
>;

type ListImagesResponse =
  paths['/api/v1/images/']['get']['responses']['200']['content']['application/json'];

type ListImagesThunkConfig = {
  rejectValue: {
    arg: ListImagesArg;
    error: unknown;
  };
};
/**
 * `ImagesService.listImagesWithMetadata()` thunk
 */
export const receivedPageOfImages = createAsyncThunk<
  ListImagesResponse,
  ListImagesArg,
  ListImagesThunkConfig
>('api/receivedPageOfImages', async (arg, { getState, rejectWithValue }) => {
  const { get } = $client.get() as any;

  const state: RootState = getState() as RootState;
  const { categories } = state.images;
  const { selectedBoardId } = state.boards;

  const images = selectImagesAll(state).filter((i) => {
    const isInCategory = categories.includes(i.image_category as "general" | "mask" | "control" | "user" | "other");
    const isInSelectedBoard = selectedBoardId
      ? i.board_id === selectedBoardId
      : true;
    return isInCategory && isInSelectedBoard;
  });

  let query: ListImagesArg = {};

  if (size(arg)) {
    query = {
      ...DEFAULT_IMAGES_LISTED_ARG,
      offset: images.length,
      ...arg,
    };
  } else {
    query = {
      ...DEFAULT_IMAGES_LISTED_ARG,
      categories,
      offset: images.length,
    };
  }

  const { data, error, response } = await get('/api/v1/images/', {
    params: {
      query,
    },
    querySerializer: (q: any) => queryString.stringify(q, { arrayFormat: 'none' }),
  });

  if (error) {
    return rejectWithValue({ arg, error });
  }

  return data;
});


export const generateThumbnail = (base64: string) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = base64;
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      const originalWidth = img.width;
      const originalHeight = img.height;

      const aspectRatio = originalWidth / originalHeight;
      let thumbnailWidth, thumbnailHeight;

      if (aspectRatio > 1) {
        // 宽度较大，按宽度为200来缩放
        thumbnailWidth = 200;
        thumbnailHeight = 200 / aspectRatio;
      } else {
        // 高度较大，按高度为200来缩放
        thumbnailHeight = 200;
        thumbnailWidth = 200 * aspectRatio;
      }

      canvas.width = thumbnailWidth;
      canvas.height = thumbnailHeight;
      ctx?.drawImage(img, 0, 0, thumbnailWidth, thumbnailHeight);

      resolve(canvas.toDataURL());
    };
  });
};

export const generateThumbnailFromFile = (file: File) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      const originalWidth = img.width;
      const originalHeight = img.height;

      const aspectRatio = originalWidth / originalHeight;
      let thumbnailWidth, thumbnailHeight;

      if (aspectRatio > 1) {
        // 宽度较大，按宽度为200来缩放
        thumbnailWidth = 200;
        thumbnailHeight = 200 / aspectRatio;
      } else {
        // 高度较大，按高度为200来缩放
        thumbnailHeight = 200;
        thumbnailWidth = 200 * aspectRatio;
      }

      canvas.width = thumbnailWidth;
      canvas.height = thumbnailHeight;
      ctx?.drawImage(img, 0, 0, thumbnailWidth, thumbnailHeight);

      resolve(canvas.toDataURL());
    };
  });
};

export const getImageSizeFromBase64 = (base64: string): Promise<{ width: number, height: number }> => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = base64;
    img.onload = () => {
      resolve({ width: img.width, height: img.height });
    };
  });
};

export const getImageSizeFromFile = (file: File): Promise<{ width: number, height: number }> => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      resolve({ width: img.width, height: img.height });
      URL.revokeObjectURL(img.src); // 释放对象URL
    };
  });
};
function dispatch(arg0: any) {
  throw new Error('Function not implemented.');
}

