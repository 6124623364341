import { RootState } from 'aldgpt_design/app/store/store';
import { ImageDTO } from 'aldgpt_design/services/api/types';
import { log } from 'aldgpt_design/app/logging/useLogger';
import { forEach } from 'lodash-es';
// import { buildCanvasInpaintGraph } from './buildCanvasInpaintGraph';
import { NonNullableGraph } from 'aldgpt_design/features/nodes/types/types';
// import { buildCanvasImageToImageGraph } from './buildCanvasImageToImageGraph';
import { buildCanvasTextToImageParam } from './buildCanvasTextToImageParam';
import { GenParams } from '../types/types';
import { buildCanvasOutpaintParam } from './buildCanvasOutpaintParam'
import { buildCanvasInpaintParam } from './buildCanvasInpaintParam';

const moduleLog = log.child({ namespace: 'nodes' });

export const buildCanvasParam = async (
  state: RootState,
  generationMode: 'txt2img' | 'img2img' | 'inpaint' | 'outpaint',
  // canvasInitImage: ImageDTO | undefined,
  // canvasMaskImage: ImageDTO | undefined
): Promise<any> => {
  let param;

  if (generationMode === 'txt2img') {
    param = await buildCanvasTextToImageParam(state);
  } else if (generationMode === 'outpaint') {
    param = await buildCanvasOutpaintParam(state);
  } else if (generationMode === 'inpaint') {
    param = await buildCanvasInpaintParam(state);
  }
  // else if (generationMode === 'img2img') {
  //   if (!canvasInitImage) {
  //     throw new Error('Missing canvas init image');
  //   }
  //   graph = buildCanvasImageToImageGraph(state, canvasInitImage);
  // } else {
  //   if (!canvasInitImage || !canvasMaskImage) {
  //     throw new Error('Missing canvas init and mask images');
  //   }
  //   graph = buildCanvasInpaintGraph(state, canvasInitImage, canvasMaskImage);
  // }

  // forEach(graph.nodes, (node) => {
  //   graph.nodes[node.id].is_intermediate = true;
  // });

  return param;
};
