export const tabMap = [
  "square",
  'txt2img',
  // 'img2img',
  // 'generate',
  'unifiedCanvas',
  // 'nodes',
  // 'postprocessing',
  // 'training'
] as const;

export type ALDTabName = (typeof tabMap)[number];
