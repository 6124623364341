import React, {
  lazy,
  memo,
  PropsWithChildren,
  ReactNode,
  useEffect,
} from 'react';
import { Provider } from 'react-redux';
import { store } from 'aldgpt_design/app/store/store';
// import { OpenAPI } from 'services/api/types';

import Loading from '../../common/components/Loading/Loading';
import { addMiddleware, resetMiddlewares } from 'redux-dynamic-middlewares';
import { PartialAppConfig } from 'aldgpt_design/app/types/aldgpt';

import '../../i18n';
import { socketMiddleware } from 'aldgpt_design/services/events/middleware';
import { Middleware } from '@reduxjs/toolkit';
import ImageDndContext from './ImageDnd/ImageDndContext';
import {
  DeleteImageContext,
  DeleteImageContextProvider,
} from 'aldgpt_design/app/contexts/DeleteImageContext';
import UpdateImageBoardModal from '../../features/gallery/components/Boards/UpdateImageBoardModal';
import { AddImageToBoardContextProvider } from '../contexts/AddImageToBoardContext';
import { $authToken, $baseUrl } from 'aldgpt_design/services/api/client';

// import  ALDGPTApp  from './ALDGPTApp';
// import  ThemeLocaleProvider  from './ThemeLocaleProvider';

const ALDGPTApp = lazy(() => import('./ALDGPTApp'));
const ThemeLocaleProvider = lazy(() => import('./ThemeLocaleProvider'));

interface Props extends PropsWithChildren<any> {
  apiUrl?: string;
  token?: string;
  config?: PartialAppConfig;
  headerComponent?: ReactNode;
  setIsReady?: (isReady: boolean) => void;
  middleware?: Middleware[];
}

const ALDGPTUI = ({
  config,
  headerComponent,
  setIsReady,
  middleware,
}: Props) => {
  useEffect(() => {
    // reset dynamically added middlewares
    resetMiddlewares();
    // TODO: at this point, after resetting the middleware, we really ought to clean up the socket
    // stuff by calling `dispatch(socketReset())`. but we cannot dispatch from here as we are
    // outside the provider. it's not needed until there is the possibility that we will change
    // the `apiUrl`/`token` dynamically.
    // rebuild socket middleware
    if (middleware && middleware.length > 0) {
      addMiddleware(socketMiddleware(), ...middleware);
    } else {
      addMiddleware(socketMiddleware());
    }
  }, [middleware]);

  return (

    <Provider store={store}>
      <React.Suspense fallback={<Loading />}>
        <ThemeLocaleProvider>
          <ImageDndContext>
            <DeleteImageContextProvider>
            <ALDGPTApp
              config={config}
              headerComponent={headerComponent}
              setIsReady={setIsReady}
            />
            </DeleteImageContextProvider>
          </ImageDndContext>
        </ThemeLocaleProvider>
      </React.Suspense>
    </Provider>

  );
};

export default memo(ALDGPTUI);
