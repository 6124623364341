import { RootState } from 'aldgpt_design/app/store/store';
import { GenParams } from '../types/types';

import { getCanvasData } from 'aldgpt_design/features/canvas/util/getCanvasData';
import { selectImagesById } from 'aldgpt_design/features/gallery/store/imagesSlice';
import { blobToDataURL } from 'aldgpt_design/features/canvas/util/blobToDataURL';

export const buildEsrganParam = async (
  state: RootState
): Promise<GenParams> => {
  const {
    positivePrompt,
    negativePrompt,
    generateType,
    iterations,
    width,
    height,
    style,
  } = state.generation;

  const { upscalingLevel,upscalingStrength, upscalingDenoising} = state.postprocessing;

  const { selectedImage } = state.gallery;
  let image: any;

  if (selectedImage !== undefined) {
    image = selectImagesById(state, selectedImage ?? '');
  }


  // const canvasBlobsAndImageData = await getCanvasData(state);
  // //const { baseBlob, baseImageData, maskBlob, maskImageData } =
  // canvasBlobsAndImageData;
  var response = await fetch(image.image_url as string)
  var blob = await response.blob();
  var base64Img = await blobToDataURL(blob);
  const graph: GenParams = {
    type: 'esrgan',
    init_image: base64Img,
    prompt: '',
    negative_prompt: '',
    generateType: generateType,
    n_iter: 1,
    width: image.width,
    height: image.height,
    style: '',
    refStructure: '',
    refStyle: '',
    baseImage: '',
    maskImage: '',
    upscaler: upscalingLevel,
    upscalingStrength: upscalingStrength,
    upscalingDenoising: upscalingDenoising,
  };

  return graph;
};