import { SchedulerParam, GenerateTypeParam, StructureTypeParam } from 'aldgpt_design/features/parameters/store/parameterZodSchemas';

// zod needs the array to be `as const` to infer the type correctly
// this is the source of the `SchedulerParam` type, which is generated by zod
export const SCHEDULER_NAMES_AS_CONST = [
  'euler',
  'deis',
  'ddim',
  'ddpm',
  'dpmpp_2s',
  'dpmpp_2m',
  'dpmpp_2m_sde',
  'dpmpp_sde',
  'heun',
  'kdpm_2',
  'lms',
  'pndm',
  'unipc',
  'euler_k',
  'dpmpp_2s_k',
  'dpmpp_2m_k',
  'dpmpp_2m_sde_k',
  'dpmpp_sde_k',
  'heun_k',
  'lms_k',
  'euler_a',
  'kdpm_2_a',
] as const;

export const DEFAULT_SCHEDULER_NAME = 'euler';

export const SCHEDULER_NAMES: SchedulerParam[] = [...SCHEDULER_NAMES_AS_CONST];

export const SCHEDULER_LABEL_MAP: Record<SchedulerParam, string> = {
  euler: 'Euler',
  deis: 'DEIS',
  ddim: 'DDIM',
  ddpm: 'DDPM',
  dpmpp_sde: 'DPM++ SDE',
  dpmpp_2s: 'DPM++ 2S',
  dpmpp_2m: 'DPM++ 2M',
  dpmpp_2m_sde: 'DPM++ 2M SDE',
  heun: 'Heun',
  kdpm_2: 'KDPM 2',
  lms: 'LMS',
  pndm: 'PNDM',
  unipc: 'UniPC',
  euler_k: 'Euler Karras',
  dpmpp_sde_k: 'DPM++ SDE Karras',
  dpmpp_2s_k: 'DPM++ 2S Karras',
  dpmpp_2m_k: 'DPM++ 2M Karras',
  dpmpp_2m_sde_k: 'DPM++ 2M SDE Karras',
  heun_k: 'Heun Karras',
  lms_k: 'LMS Karras',
  euler_a: 'Euler Ancestral',
  kdpm_2_a: 'KDPM 2 Ancestral',
};

export type Scheduler = (typeof SCHEDULER_NAMES)[number];

//生成类型
export const GENERATETYPE_NAMES_AS_CONST = [
  'decoration',
  'architecture',
  'landscape',
  'plane',
  'general',
] as const;

export const DEFAULT_GENERATETYPE_NAME = 'decoration';

export const GENERATETYPE_NAMES: GenerateTypeParam[] = [...GENERATETYPE_NAMES_AS_CONST];

export const GENERATETYPE_LABEL_MAP: Record<GenerateTypeParam, string> = {
  decoration: '装饰',
  architecture: '建筑',
  landscape: '景观',
  plane: '平面彩图',
  general: '通用',
};

export type GenerateType = (typeof GENERATETYPE_NAMES)[number];


//参照结构类型
export const STRUCTURETYPE_NAMES_AS_CONST = [
  'accurate',
  'originality',
] as const;

export const DEFAULT_STRUCTURETYPE_NAME = 'accurate';

export const STRUCTURETYPE_NAMES: StructureTypeParam[] = [...STRUCTURETYPE_NAMES_AS_CONST];

export const STRUCTURETYPE_LABEL_MAP: Record<StructureTypeParam, string> = {
  accurate: '渲染模式',
  originality: '创意模式',
};

export type StructureType = (typeof STRUCTURETYPE_NAMES)[number];

// Valid upscaling levels
export const UPSCALING_LEVELS: Array<{ label: string; value: string }> = [
  { label: '2x', value: '2' },
  { label: '4x', value: '4' },
];
export const NUMPY_RAND_MIN = 0;

export const NUMPY_RAND_MAX = 2147483647;

export const FACETOOL_TYPES = ['gfpgan', 'codeformer'] as const;

export const NODE_MIN_WIDTH = 250;
