import { stagingAreaImageSaved } from 'aldgpt_design/features/canvas/store/actions';
import { startAppListening } from '..';
import { log } from 'aldgpt_design/app/logging/useLogger';
import { imageUpdated } from 'aldgpt_design/services/api/thunks/image';
import { imageUpserted, selectImagesById } from 'aldgpt_design/features/gallery/store/imagesSlice';
import { addToast } from 'aldgpt_design/features/system/store/systemSlice';

const moduleLog = log.child({ namespace: 'canvas' });

export const addStagingAreaImageSavedListener = () => {
  startAppListening({
    actionCreator: stagingAreaImageSaved,
    effect: async (action, { dispatch, getState, take }) => {
      const { imageName } = action.payload;
      const state = getState();
      const image = selectImagesById(state, imageName ?? '');
      //const image = await db.getImage(imageName);
      const data = {
        image_name: imageName,
        image_category: 'general',
        thumbnail_url: image?.thumbnail_url,
        width: image?.width as number,
        height: image?.height as number,
      };

      dispatch(imageUpserted(data));
      dispatch(addToast({ title: '添加到图片库', status: 'success' }));
      // dispatch(
      //   imageUpdated({
      //     image_name: imageName,
      //     is_intermediate: false,
      //   })
      // );

      // const [imageUpdatedAction] = await take(
      //   (action) =>
      //     (imageUpdated.fulfilled.match(action) ||
      //       imageUpdated.rejected.match(action)) &&
      //     action.meta.arg.image_name === imageName
      // );

      // if (imageUpdated.rejected.match(imageUpdatedAction)) {
      //   moduleLog.error(
      //     { data: { arg: imageUpdatedAction.meta.arg } },
      //     'Image saving failed'
      //   );
      //   dispatch(
      //     addToast({
      //       title: 'Image Saving Failed',
      //       description: imageUpdatedAction.error.message,
      //       status: 'error',
      //     })
      //   );
      //   return;
      // }

      // if (imageUpdated.fulfilled.match(imageUpdatedAction)) {
      //   dispatch(imageUpserted(imageUpdatedAction.payload));
      //   dispatch(addToast({ title: 'Image Saved', status: 'success' }));
      // }
    },
  });
};
