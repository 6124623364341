import { createAction } from '@reduxjs/toolkit';
import { ALDTabName } from 'aldgpt_design/features/ui/store/tabMap';

export const userInvoked = createAction<ALDTabName>('app/userInvoked');

export const receivedMessage = createAction<any>('app/receivedMessage');

export const postMessage = createAction<any>('app/postMessage');


