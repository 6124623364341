import { createAction } from '@reduxjs/toolkit';
import { ImageDTO } from 'aldgpt_design/services/api/types';
import { ALDTabName } from 'aldgpt_design/features/ui/store/tabMap';

export const initialImageSelected = createAction<ImageDTO | string | undefined>(
  'generation/initialImageSelected'
);

export const genGraph = createAction<ALDTabName>(
  'generation/genGraph'
);

export const runESRGAN = createAction<string>(
  'generation/runESRGAN'
);

export const refreshGenCoin = createAction(
  'generation/refreshGenCoin'
);


