export const decoration = {
  interior_space:["客厅", "卧室", "厨房", "餐厅", "卫生间"],
  style:["新中式", "欧式", "现代简约", "北欧", "英伦", "巴洛克", "波西米亚", "地中海", "和式", "侘寂", "法国浪漫", "大美式", "中式经典", "南洋", "Art deco", "轻奢", "田园", "东南亚", "古罗马", "黑白", "宜家", "乡村", "新古典主义", "哥特"],
  pic_quality:["超强细节", "最优画质", "大师作品", "超高分辨率", "写实", "超写实", "真实的"],
  lighting_style:["白天光照", "夜间光照", "自然光", "月光", "光线追踪", "全局光", "聚光灯", "背光", "泛光灯", "体积照明", "昏暗灯光", "工作室光", "电影照明"]
}

export const architecture = {
  main_body:["住宅", "公寓", "别墅", "商业建筑", "办公楼", '酒店', "医院", "学校", "幼儿园", "图书馆", "博物馆", "剧院", "体育馆", "会议中心", "机场", "火车站", "地铁站", "桥梁", "塔楼", "摩天大楼", "工厂", "仓库", "农场建筑", "港口建筑", "电视塔", "电影院", "超市", "百货商店", "餐厅", "咖啡馆", "酒吧", "夜总会", "游乐园", "水上乐园", "城堡", "教堂", "寺庙"],
  style:["极简主义", "复古", "流行艺术", "现代主义", "后现代主义", "哥特式", "巴洛克", "洛可可", "文艺复兴", "新艺术", "包豪斯", "瑞士风格", "野兽主义", "新古典主义", "未来主义", "超现实主义", "达达主义", "表现主义", "印象主义", "点彩派", "野兽派", "立体主义", "抽象艺术", "视幻艺术"],
  pic_quality: ["超强细节", "最优画质", "大师作品", "超高分辨率", "写实", "超写实", "真实的"],
  camera_perspective:["鸟瞰视角", "透视图", "两点透视", "高角度视角", "低视角", "顶视图", "侧视图", "等轴测图", "轴测图"],
  lighting_style:["白天光照", "夜间光照", "自然光", "月光", "光线追踪", "全局光", "聚光灯", "背光", "泛光灯", "体积照明", "昏暗灯光", "工作室光", "电影照明"]
}