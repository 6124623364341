import { RootState } from 'aldgpt_design/app/store/store';
import { GenParams } from '../types/types';

import { getCanvasData } from 'aldgpt_design/features/canvas/util/getCanvasData';
import { selectImagesById } from 'aldgpt_design/features/gallery/store/imagesSlice';

export const buildCanvasOutpaintParam = async (
    state: RootState
): Promise<GenParams> => {
    const {
        positivePrompt,
        negativePrompt,
        generateType,
        iterations,
        style,
    } = state.generation;

    const { width, height } = state.canvas.boundingBoxDimensions;

    const { baseDataURL, outMaskDataURL } = await getCanvasData(state) ?? {};;


    const {
        structureImageName,
        isStructureEnabled,
    } = state.advancedParam;

    let refStructure: string | null = '';
    if (isStructureEnabled && structureImageName) {
        const imageDTO = selectImagesById(state, structureImageName ?? '');
        refStructure = imageDTO?.image_url as string;
        //refStructure = await db.getImageUrl(structureImageName) as string;
    }


    // const canvasBlobsAndImageData = await getCanvasData(state);
    // //const { baseBlob, baseImageData, maskBlob, maskImageData } =
    // canvasBlobsAndImageData;

    const graph: GenParams = {
        type: 'Outpaint',
        prompt: positivePrompt,
        negative_prompt: negativePrompt,
        generateType: generateType,
        n_iter: iterations,
        width: width,
        height: height,
        style: style,
        refStructure: refStructure,
        baseImage: baseDataURL,
        maskImage: outMaskDataURL as string,
    };

    return graph;
};