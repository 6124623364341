import { postMessage, receivedMessage, userInvoked } from "aldgpt_design/app/store/actions";
import { startAppListening } from "..";
import { tabMap } from "aldgpt_design/features/ui/store/tabMap";

export const addReceivedMessageListener = () => {
    startAppListening({
        actionCreator: receivedMessage,
        effect: async (action, { getState, dispatch }) => {
            const msg: any = action.payload;
            if (msg["action"] === 'userinfo') {
                const state = getState();
                const { genCoin } = state.generation;
                const { activeTab } = state.ui;
                const activeTabName = tabMap[activeTab];

                const userTotalcoin = msg["totalcoin"];
                if (userTotalcoin >= genCoin) {
                    //window.alert("钱够！")
                    dispatch(userInvoked(activeTabName));
                }
                else {
                    //window.alert("钱不够！")
                    dispatch(postMessage({ action: 'recharge' }));
                }
            }
        },
    });
};