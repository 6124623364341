export const actionsDenylist = [
  'canvas/setCursorPosition',
  'canvas/setStageCoordinates',
  'canvas/setStageScale',
  'canvas/setIsDrawing',
  'canvas/setBoundingBoxCoordinates',
  'canvas/setBoundingBoxDimensions',
  'canvas/setIsDrawing',
  'canvas/addPointToCurrentLine',
  'socket/socketGeneratorProgress',
  'socket/appSocketGeneratorProgress',
];
