import { postMessage } from "aldgpt_design/app/store/actions";
import { startAppListening } from "..";

export const addPostMessageListener = () => {
    startAppListening({
        actionCreator: postMessage,
        effect: async (action, { getState, dispatch }) => {
            //window.alert(JSON.stringify(action.payload));
            window.parent.postMessage(action.payload, '*');
        },
    });
};