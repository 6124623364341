import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DEFAULT_STRUCTURETYPE_NAME } from 'aldgpt_design/app/constants';
import { RootState } from 'aldgpt_design/app/store/store';
import { StructureTypeParam } from 'aldgpt_design/features/parameters/store/parameterZodSchemas';


export interface AdvancedParamState {
  isEnabled: boolean;
  isStructureEnabled: boolean;
  isStepsEnabled:boolean;
  isStyleEnabled: boolean;
  structureImageName: string | null ;
  styleImageName: string | null;
  structureType: StructureTypeParam;
}

export const initialAdvancedParamState: AdvancedParamState = {
  isEnabled: false,
  isStructureEnabled: false,
  isStepsEnabled: false,
  isStyleEnabled: false,
  structureImageName: "" ,
  styleImageName:"",
  structureType:DEFAULT_STRUCTURETYPE_NAME,
};

const initialState: AdvancedParamState = initialAdvancedParamState;

export const advancedParamSlice = createSlice({
  name: 'advancedParam',
  initialState,
  reducers: {
    isEnabledToggled: (state) => {
      state.isEnabled = !state.isEnabled;
    },
    isStructureEnabledToggled: (state) => {
      state.isStructureEnabled = !state.isStructureEnabled;
    },
    setStyleEnabled:(state,action:PayloadAction<boolean>)=>{
      state.isStyleEnabled = action.payload;
    },
    setStructureEnabled: (state,action:PayloadAction<boolean>) => {
      state.isStructureEnabled = action.payload;
    },
    isStyleEnabledToggled: (state) => {
      state.isStyleEnabled = !state.isStyleEnabled;
    },
    isStepsEnabledToggled: (state) => {
      state.isStepsEnabled = !state.isStepsEnabled;
    },
    setStructureType: (state, action: PayloadAction<StructureTypeParam>) => {
      state.structureType = action.payload;
    },
    structureImageChanged: (
      state,
      action: PayloadAction<{
        structureImage: string | null;
      }>
    ) => {
      const { structureImage } = action.payload;
      state.structureImageName = structureImage;
    },
    styleImageChanged: (
      state,
      action: PayloadAction<{
        styleImage: string | null;
      }>
    ) => {
      const { styleImage } = action.payload;
      state.styleImageName = styleImage;
    },
  },
  extraReducers: (builder) => {
    //
  },
});

export const {
  isEnabledToggled,
  isStructureEnabledToggled,
  isStyleEnabledToggled,
  isStepsEnabledToggled,
  structureImageChanged,
  setStructureType,
  styleImageChanged,
  setStructureEnabled,
  setStyleEnabled
} = advancedParamSlice.actions;

export default advancedParamSlice.reducer;

export const advancedParamSelector = (state: RootState) =>
  state.advancedParam;
