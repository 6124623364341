import { refreshGenCoin } from "aldgpt_design/features/parameters/store/actions";
import { startAppListening } from '..';
import { tabMap } from "aldgpt_design/features/ui/store/tabMap";
import { buildTextToImageParam } from "aldgpt_design/features/parameters/paramBuilders/buildTextToImageParam";
import { setGenCoin } from "aldgpt_design/features/parameters/store/generationSlice";

export const addRefreshGenListener = () => {
    startAppListening({
        actionCreator: refreshGenCoin,
        effect: async (action, { getState, dispatch }) => {
            const state = getState();
            const { activeTab } = state.ui;
            const activeTabName = tabMap[activeTab];

            const getSizeMultiple = (size: number) => {
                const result = Math.ceil((size - 512 * 512) / 64 / 2048);
                return result < 0 ? 0 : result;
             }

            let coin = 1;
            if (activeTabName === 'txt2img') {
                const params = await buildTextToImageParam(state);
                coin = coin + getSizeMultiple(params.height * params.width);
                coin = params.style === "通用" ? coin : coin + 1;
                coin = params.refStructure ? coin + 1 : coin;
                coin = params.refStyle ? coin + 1 : coin;
                coin = coin * params.n_iter;
            }

            dispatch(setGenCoin(coin));

        },
    });
};