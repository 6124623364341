import { requestedImageDeletion } from 'aldgpt_design/features/gallery/store/actions';
import { startAppListening } from '..';
import { imageDeleted } from 'aldgpt_design/services/api/thunks/image';
import { log } from 'aldgpt_design/app/logging/useLogger';
import { clamp } from 'lodash-es';
import { imageSelected } from 'aldgpt_design/features/gallery/store/gallerySlice';
import {
  imageRemoved,
  selectImagesAll,
  selectImagesIds,
} from 'aldgpt_design/features/gallery/store/imagesSlice';
import { resetCanvas } from 'aldgpt_design/features/canvas/store/canvasSlice';
import { controlNetReset } from 'aldgpt_design/features/controlNet/store/controlNetSlice';
import { clearInitialImage } from 'aldgpt_design/features/parameters/store/generationSlice';
import { nodeEditorReset } from 'aldgpt_design/features/nodes/store/nodesSlice';
import { api } from 'aldgpt_design/services/api';
import { Client4 } from 'aldgpt_design/common/util/client';
const moduleLog = log.child({ namespace: 'image' });

/**
 * Called when the user requests an image deletion
 */
export const addRequestedImageDeletionListener = () => {
  startAppListening({
    actionCreator: requestedImageDeletion,
    effect: async (action, { dispatch, getState, condition }) => {
      const { image, imageUsage } = action.payload;
      const { image_name } = image;
      const state = getState();
      const selectedImage = state.gallery.selectedImage;
      const galleryView  = state.gallery.galleryView;
      if (selectedImage === image_name) {
        var  ids;
        var images = selectImagesAll(state);
        if(galleryView=="images"){
          ids = images.filter(image=>image.image_category =='general').map(image=>image.image_name)
        }else if(galleryView=="st-assets"){
          ids = images.filter(image=>image.type=="TextToImage").map(image=>image.image_name)
        }else if(galleryView=="gt-assets"){
          ids = images.filter(image=>image.type=="Outpaint").map(image=>image.image_name)
        }else{
          ids = images.filter(image=>image.type=="Upload").map(image=>image.image_name)
        }

        const deletedImageIndex = ids.findIndex(
          (result) => result.toString() === image_name
        );

        const filteredIds = ids.filter((id) => id.toString() !== image_name);

        const newSelectedImageIndex = clamp(
          deletedImageIndex,
          0,
          filteredIds.length - 1
        );

        const newSelectedImageId = filteredIds[newSelectedImageIndex];

        if (newSelectedImageId) {
          dispatch(imageSelected(newSelectedImageId as string));
        } else {
          dispatch(imageSelected());
        }
        
      }
      // We need to reset the features where the image is in use - none of these work if their image(s) don't exist
      if (imageUsage.isCanvasImage) {
        dispatch(resetCanvas());
      }
      if (imageUsage.isControlNetImage) {
        dispatch(controlNetReset());
      }
      if (imageUsage.isInitialImage) {
        dispatch(clearInitialImage());
      }
      if (imageUsage.isNodesImage) {
        dispatch(nodeEditorReset());
      }
      // Preemptively remove from gallery
      if(galleryView=="images"){
        dispatch(imageRemoved(image_name));
      }else{
        dispatch(imageRemoved(image_name));
        Client4.deleteContent(image.id as string);
      }
      // Delete from server
      const { requestId } = (dispatch as any)(imageDeleted({ image_name }));
      // Wait for successful deletion, then trigger boards to re-fetch
      const wasImageDeleted = await condition(
        (action): action is ReturnType<typeof imageDeleted.fulfilled> =>
          imageDeleted.fulfilled.match(action) &&
          action.meta.requestId === requestId,
        30000
      );

      if (wasImageDeleted) {
        dispatch(
          api.util.invalidateTags([{ type: 'Board', id: image.board_id }])
        );
      }
    },
  });
};

/**
 * Called when the actual delete request is sent to the server
 */
export const addImageDeletedPendingListener = () => {
  startAppListening({
    actionCreator: imageDeleted.pending,
    effect: (action, { dispatch, getState }) => {
      //
    },
  });
};

/**
 * Called on successful delete
 */
export const addImageDeletedFulfilledListener = () => {
  startAppListening({
    actionCreator: imageDeleted.fulfilled,
    effect: (action, { dispatch, getState }) => {
      moduleLog.debug({ data: { image: action.meta.arg } }, 'Image deleted');
    },
  });
};

/**
 * Called on failed delete
 */
export const addImageDeletedRejectedListener = () => {
  startAppListening({
    actionCreator: imageDeleted.rejected,
    effect: (action, { dispatch, getState }) => {
      moduleLog.debug(
        { data: { image: action.meta.arg } },
        'Unable to delete image'
      );
    },
  });
};
