import { generateThumbnail, getImageSizeFromBase64 } from 'aldgpt_design/services/api/thunks/image';
import { socketAcceptParameter } from 'aldgpt_design/services/events/actions';
import { startAppListening } from '../..';
import { imageUpserted } from 'aldgpt_design/features/gallery/store/imagesSlice';
import { addImageToStagingArea } from 'aldgpt_design/features/canvas/store/canvasSlice';

export const addsocketAcceptParameterEventListener = () => {
  startAppListening({
    actionCreator: socketAcceptParameter,
    effect: async (action, { dispatch, getState }) => {
      const data = {
        image_name: action.payload.id,
        image_category: 'general',
        image_url: action.payload.image_url,
        thumbnail_url: action.payload.thumbnail_url,
        width: action.payload.width,
        height: action.payload.height,
        id: action.payload.id,
        show_in_gt: action.payload.show_in_gt,
        show_in_st: action.payload.show_in_st
      };

      if (action.payload.genType === 'txt2img') {
        dispatch(imageUpserted(data));
      } else if (action.payload.genType === 'canvas2img') {
        dispatch(imageUpserted(data));
        dispatch(addImageToStagingArea(data));
      } else if (action.payload.genType === 'esrgan') {
        dispatch(imageUpserted(data));
      }

    },
  });
};