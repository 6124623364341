import { Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
import { io, Socket } from 'socket.io-client';
import { AppThunkDispatch, RootState } from 'aldgpt_design/app/store/store';
// import { sessionCreated } from 'aldgpt_design/services/api/thunks/session';
// import { OpenAPI } from 'services/api/types';
import { setEventListeners } from 'aldgpt_design/services/events/util/setEventListeners';
import { log } from 'aldgpt_design/app/logging/useLogger';
import { genGraph, runESRGAN } from 'aldgpt_design/features/parameters/store/actions';
import { progressImageSet, setIsProcessing } from 'aldgpt_design/features/system/store/systemSlice';

const socketioLog = log.child({ namespace: 'socketio' });

export const socketMiddleware = () => {
  let areListenersSet = false;
  const host = process.env.REACT_APP_SERVER_HOST || window.location.hostname;
  const port = process.env.REACT_APP_SERVER_PORT || window.location.port;

  //发布替换
  // const socketUrl = 'http://test.aldbim.com';
  const socketUrl = 'https://ai.aldgptai.com';
  // const socketUrl = 'ws://127.0.0.1:1001';
  const socketOptions = {
    timeout: 60000,
    transports: ['websocket'],
    path: '/image.io',
    query: { type: 'user' },
    autoConnect: false
  };

  // if building in package mode, replace socket url with open api base url minus the http protocol
  //axel
  // if (['nodes', 'package'].includes(import.meta.env.MODE)) {
  //   const baseUrl = $baseUrl.get();
  //   if (baseUrl) {
  //     //eslint-disable-next-line
  //     socketUrl = baseUrl.replace(/^https?\:\/\//i, '');
  //   }

  //   const authToken = $authToken.get();
  //   if (authToken) {
  //     // TODO: handle providing jwt to socket.io
  //     socketOptions.auth = { token: authToken };
  //   }

  //   socketOptions.transports = ['websocket', 'polling'];
  // }

  const socket: Socket = io(
    socketUrl,
    socketOptions
  );


  const middleware: Middleware =
    (storeApi: MiddlewareAPI<AppThunkDispatch, RootState>) =>
      (next) =>
        (action) => {
          const { dispatch } = storeApi;

          if (!areListenersSet) {
            setEventListeners({ storeApi, socket, log: socketioLog });
            areListenersSet = true;
          }

          if (genGraph.match(action) || runESRGAN.match(action)) {
            if (action.payload === 'txt2img' || action.payload === 'ESRGAN') {
              dispatch(progressImageSet({
                dataURL: 'assets/images/a1.gif',
                width: 500,
                height: 500,
              }));
              dispatch(setIsProcessing(true));
            }
            socket.connect();
          }

          // if (sessionCreated.pending.match(action)) {
          //   //socket.connect();        
          // }

          // if (sessionCreated.fulfilled.match(action)) {
          //   const sessionId = action.payload.id;
          //   const oldSessionId = getState().system.sessionId;
          // }

          next(action);
        };

  return middleware;
};
