import { MiddlewareAPI } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from 'aldgpt_design/app/store/store';
import { getTimestamp } from 'aldgpt_design/common/util/getTimestamp';
import { Socket } from 'socket.io-client';
import {
  socketGeneratorProgress,
  socketGraphExecutionStateComplete,
  socketInvocationComplete,
  socketInvocationError,
  socketInvocationStarted,
  socketConnected,
  socketDisconnected,
  socketSubscribed,
  socketAcceptParameter,
} from '../actions';
import { ClientToServerEvents, ServerToClientEvents } from '../types';
import { Logger } from 'roarr';
import { JsonObject } from 'roarr/dist/types';
import { makeToast } from 'aldgpt_design/app/components/Toaster';
import { addToast, progressImageSet, setIsProcessing } from 'aldgpt_design/features/system/store/systemSlice';
import Peer from 'simple-peer';
// import { buildTextToImageParm } from 'aldgpt_design/features/parameters/paramBuilders/buildTextToImageParm'
import { createPeer } from './peerUtils';
import { buildTextToImageParam } from 'aldgpt_design/features/parameters/paramBuilders/buildTextToImageParam';
import { setShouldShowAnimationBox } from 'aldgpt_design/features/canvas/store/canvasSlice';
import { GenParams } from 'aldgpt_design/features/parameters/types/types';
import { getCanvasData } from 'aldgpt_design/features/canvas/util/getCanvasData';
import { getCanvasGenerationMode } from 'aldgpt_design/features/canvas/util/getCanvasGenerationMode';
import { buildCanvasParam } from 'aldgpt_design/features/parameters/paramBuilders/buildCanvasParam';
import { buildEsrganParam } from 'aldgpt_design/features/parameters/paramBuilders/buildEsrganParam';
import { Client4 } from 'aldgpt_design/common/util/client';
import { Content, SaveContent } from 'aldgpt_design/app/types/content';
import { postMessage } from "aldgpt_design/app/store/actions";
import { imageUpserted } from 'aldgpt_design/features/gallery/store/imagesSlice';
import { imageSelected } from 'aldgpt_design/features/gallery/store/gallerySlice';
import { decoration, architecture } from './data';

type SetEventListenersArg = {
  socket: Socket;
  storeApi: MiddlewareAPI<AppDispatch, RootState>;
  log: Logger<JsonObject>;
};

export const setEventListeners = (arg: SetEventListenersArg) => {
  let peer: any;
  const { socket, storeApi, log } = arg;
  const { dispatch, getState } = storeApi;
  let imgNum: number;
  let imgCount: number;
  let params: GenParams;
  let genType = '';
  /**
   * Connect
   */
  socket.on('connect', async () => {
    log.debug('Connected');
    const state = getState();
    const { currentGen } = state.generation;

    if (currentGen === 'TXT2IMG') {
      genType = 'txt2img';
      params = await buildTextToImageParam(state);
      if (params.prompt === "" && params.refStructure == "" && params.refStyle == "") {
        params.isPromptNull = true;
        if (params.generateType === "decoration") {
          params.prompt =
            decoration.interior_space[Math.floor(Math.random() * decoration.interior_space.length)] + ","
            + decoration.style[Math.floor(Math.random() * decoration.style.length)] + ","
            + decoration.pic_quality[Math.floor(Math.random() * decoration.pic_quality.length)] + ","
            + decoration.lighting_style[Math.floor(Math.random() * decoration.lighting_style.length)]
        } else if (params.generateType === "architecture") {
          params.prompt =
            architecture.main_body[Math.floor(Math.random() * architecture.main_body.length)] + ","
            + architecture.style[Math.floor(Math.random() * architecture.style.length)] + ","
            + architecture.pic_quality[Math.floor(Math.random() * architecture.pic_quality.length)] + ","
            + architecture.camera_perspective[Math.floor(Math.random() * architecture.camera_perspective.length)] + ","
            + architecture.lighting_style[Math.floor(Math.random() * architecture.lighting_style.length)]
        }
      }
      // console.log(params, "params22>>>>>>>>>>>>>>")
    } else if (currentGen === 'CANVAS2IMG') {
      genType = 'canvas2img';
      const canvasBlobsAndImageData = await getCanvasData(state);
      const { baseImageData, maskImageData } = canvasBlobsAndImageData as { baseImageData: ImageData; maskImageData: ImageData; };
      // Determine the generation mode
      const generationMode = getCanvasGenerationMode(baseImageData, maskImageData);
      if (generationMode === 'outpaint') {
        dispatch(setShouldShowAnimationBox(true));
      }
      params = await buildCanvasParam(state, generationMode);
      console.log(">>11111", params)
    } else if (currentGen === 'ESRGAN') {
      genType = 'esrgan';
      params = await buildEsrganParam(state);
    }
    imgNum = params!.n_iter;
    imgCount = 0;
    socket.emit('gen-request', params);
    //socket.emit('session-request', params);
  });



  socket.on("gen-answer", async data => {
    const searchParam = new URLSearchParams(window.location.search);
    const userId = searchParam.get('userId') || "nuyi468a3prbiptn5f97uu4nie";
    const Prompt = params.isPromptNull ? '' : params?.prompt;
    let saveParams = {
      image_url: data.image_url,
      ref_structure: params?.refStructure || '',
      ref_structure_type: params?.refStructureType || '',
      ref_style: params?.refStyle || '',
      style: params?.style || '',
      prompt: Prompt,
      negative_prompt: params?.negative_prompt || '',
      width: params?.width || 0,
      height: params?.height || 0,
      generate_type: params?.generateType || '',
      user_id: userId,
      thumbnail_url: data.thumbnail_url,
      type: params?.type || '',
      image_name: data.file_name,
      structure_image_name: params?.structureImageName || '',
      style_image_name: params?.styleImageName || ''
    }

    try {
      if (saveParams.type !== "Upload") {
        const res: Content = await Client4.saveContent(saveParams as SaveContent);
        if (res) {
          dispatch(socketAcceptParameter({
            id: res.id || '',
            genType: genType,
            fileName: res.image_name,
            image_url: data.image_url,
            thumbnail_url: data.thumbnail_url,
            width: data.width,
            height: data.height,
            show_in_gt: res.type=="Outpaint",
            show_in_st: res.type=="TextToImage",
          }));
          
          imgCount = imgCount + 1;
          if (imgCount == imgNum) {
            params.baseImage = ""
            params.maskImage = ""
            const state = getState();
            const { genCoin } = state.generation;
            dispatch(postMessage({
              action: "payment",
              amount: genCoin,
              type: "生图",
              detail: params
            }));
            socket.disconnect();
            dispatch(setShouldShowAnimationBox(false));
            dispatch(progressImageSet(null));
            dispatch(setIsProcessing(false));
          }
        }
      }
    } catch (error) {
      return error;
    }
  });


  socket.on("signal-answer", data => {
    peer.signal(data.signal);
  });

  socket.on("signal-answer", data => {
    peer.signal(data.signal);
  });

  socket.on("session-response", data => {
    //peer = createPeer(socket, dispatch, getState(), data.PEER_ID);
  });

  socket.on('connect_error', (error) => {
    if (error && error.message) {
      const data: string | undefined = (error as any).data;
      if (data === 'ERR_UNAUTHENTICATED') {
        dispatch(
          addToast(
            makeToast({
              title: error.message,
              status: 'error',
              duration: 10000,
            })
          )
        );
      }
    }
  });

  /**
   * Disconnect
   */
  socket.on('disconnect', () => {
    //dispatch(socketDisconnected({ timestamp: getTimestamp() }));
  });

  /**
   * Invocation started
   */
  socket.on('invocation_started', (data) => {
    dispatch(socketInvocationStarted({ data, timestamp: getTimestamp() }));
  });

  /**
   * Generator progress
   */
  socket.on('generator_progress', (data) => {
    dispatch(socketGeneratorProgress({ data, timestamp: getTimestamp() }));
  });

  /**
   * Invocation error
   */
  socket.on('invocation_error', (data) => {
    dispatch(socketInvocationError({ data, timestamp: getTimestamp() }));
  });

  /**
   * Invocation complete
   */
  socket.on('invocation_complete', (data) => {
    dispatch(
      socketInvocationComplete({
        data,
        timestamp: getTimestamp(),
      })
    );
  });

  /**
   * Graph complete
   */
  socket.on('graph_execution_state_complete', (data) => {
    dispatch(
      socketGraphExecutionStateComplete({
        data,
        timestamp: getTimestamp(),
      })
    );
  });
};
