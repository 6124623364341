import Konva from 'konva';
import { IRect } from 'konva/lib/types';
import { canvasToBlob } from './canvasToBlob';
import { isCanvasBaseImage } from '../store/canvasTypes';
/**
 * Converts a Konva node to a Blob
 * @param node - The Konva node to convert to a Blob
 * @param boundingBox - The bounding box to crop to
 * @returns A Promise that resolves with Blob of the node cropped to the bounding box
 */
export const getCanvasMaskBase64 = async (
  node: Konva.Node,
  boundingBox: IRect
): Promise<String> => {

  const objects = (node as Konva.Layer).getChildren();
  const canvasBaseImage = traverseObjects(objects);

  // 创建一个新的div元素
  const container = document.createElement('div');
  container.id = 'temp-container';
  document.body.appendChild(container);

  // 获取 node 的宽度和高度
  const nodeWidth = canvasBaseImage.width();
  const nodeHeight = canvasBaseImage.height();

  // 获取 node 在 boundingBox 中的坐标
  const nodeX = canvasBaseImage.x() - boundingBox.x;
  const nodeY = canvasBaseImage.y() - boundingBox.y;

  // 创建一个新的 Konva.Stage
  const stage = new Konva.Stage({
    width: boundingBox.width,
    height: boundingBox.height,
    container: 'temp-container'
  });

  // 创建一个新的 Konva.Layer
  const layer = new Konva.Layer();

  // 创建一个黑色矩形，覆盖整个 boundingBox
  const blackRect = new Konva.Rect({
    x: 0,
    y: 0,
    width: boundingBox.width,
    height: boundingBox.height,
    fill: 'white'
  });
  layer.add(blackRect);

  // 创建一个白色矩形，覆盖 node
  const whiteRect = new Konva.Rect({
    x: nodeX,
    y: nodeY,
    width: nodeWidth,
    height: nodeHeight,
    fill: 'black'
  });
  layer.add(whiteRect);

  // 将 layer 添加到 stage
  stage.add(layer);

  // 获取 dataURL
  const dataURL = stage.toDataURL();


  const container2 = document.getElementById('temp-container');

  // 从文档中删除元素
  if (container2 && container2.parentNode) {
    container2.parentNode.removeChild(container2);
  }

  return dataURL;
};

// function blobToBase64(blob) {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.onloadend = () => {
//       resolve(reader.result);
//     };
//     reader.onerror = reject;
//     reader.readAsDataURL(blob);
//   });
// };

const traverseObjects = (objects:any): any => {
  // 遍历当前层级的对象
  for (let i = 0; i < objects.length; i++) {
    const object = objects[i];
    // 对当前对象执行您需要的操作
    if (object.className === 'Image') {
      // 符合条件的对象，返回并退出遍历
      return object;
    }

    // 检查当前对象是否有子对象
    if (object.hasChildren()) {
      const children = object.getChildren();
      // 递归遍历子对象
      const result = traverseObjects(children);
      // 如果在子对象中找到匹配的对象，则传递给上一级，并退出遍历
      if (result) {
        return result;
      }
    }
  }

  // 没有找到匹配的对象，返回null
  return null;
};

