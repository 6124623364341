import Konva from 'konva';
import { IRect } from 'konva/lib/types';
import { dataURLToImageData } from './dataURLToImageData';
import openBase64ImageInTab from 'aldgpt_design/common/util/openBase64ImageInTab';
/**
 * Converts a Konva node to an ImageData object
 * @param node - The Konva node to convert to an ImageData object
 * @param boundingBox - The bounding box to crop to
 * @returns A Promise that resolves with ImageData object of the node cropped to the bounding box
 */
export const konvaNodeToImageData = async (
  node: Konva.Node,
  boundingBox: IRect
): Promise<ImageData> => {
  // get a dataURL of the bbox'd region
  const dataURL = node.toDataURL(boundingBox);


  // // 创建一个新的div元素
  // const container = document.createElement('div');
  // container.id = 'temp-container';
  // document.body.appendChild(container);

  // // 获取 node 的宽度和高度
  // const nodeWidth = node.width();
  // const nodeHeight = node.height();

  // // 获取 node 在 boundingBox 中的坐标
  // const nodeX = node.x() - boundingBox.x;
  // const nodeY = node.y() - boundingBox.y;

  // // 创建一个新的 Konva.Stage
  // const stage = new Konva.Stage({
  //   width: boundingBox.width,
  //   height: boundingBox.height,
  //   container: 'temp-container'
  // });

  // // 创建一个新的 Konva.Layer
  // const layer = new Konva.Layer();

  // // 创建一个黑色矩形，覆盖整个 boundingBox
  // const blackRect = new Konva.Rect({
  //   x: 0,
  //   y: 0,
  //   width: boundingBox.width,
  //   height: boundingBox.height,
  //   fill: 'black'
  // });
  // layer.add(blackRect);

  // // 创建一个白色矩形，覆盖 node
  // const whiteRect = new Konva.Rect({
  //   x: nodeX,
  //   y: nodeY,
  //   width: nodeWidth,
  //   height: nodeHeight,
  //   fill: 'white'
  // });
  // layer.add(whiteRect);

  // // 将 layer 添加到 stage
  // stage.add(layer);

  // // 获取 dataURL
  // const dataURL2 = stage.toDataURL();


  // const container2 = document.getElementById('temp-container');

  // // 从文档中删除元素
  // if (container2 && container2.parentNode) {
  //   container2.parentNode.removeChild(container2);
  // }


  // openBase64ImageInTab([
  //   { base64: dataURL, caption: 'dataURL2 b64' },
  // ]);

  return await dataURLToImageData(
    dataURL,
    boundingBox.width,
    boundingBox.height
  );
};
