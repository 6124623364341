import { startAppListening } from '..';
// import { sessionCreated } from 'aldgpt_design/services/api/thunks/session';
import { log } from 'aldgpt_design/app/logging/useLogger';
import { textToImageGraphBuilt } from 'aldgpt_design/features/nodes/store/actions';
import { userInvoked } from 'aldgpt_design/app/store/actions';
import { sessionReadyToInvoke } from 'aldgpt_design/features/system/store/actions';
import { buildLinearTextToImageGraph } from 'aldgpt_design/features/nodes/util/graphBuilders/buildLinearTextToImageGraph';
//import { buildTextToImageParm } from 'aldgpt_design/features/parameters/paramBuilders/buildTextToImageParm'
import { genGraph } from 'aldgpt_design/features/parameters/store/actions';
import { setCurrentGen } from 'aldgpt_design/features/parameters/store/generationSlice';

const moduleLog = log.child({ namespace: 'invoke' });

export const addUserInvokedTextToImageListener = () => {
  startAppListening({
    predicate: (action): action is ReturnType<typeof userInvoked> =>
      userInvoked.match(action) && action.payload === 'txt2img',
    effect: async (action, { getState, dispatch, take }) => {
      const state = getState();

      const graph = buildLinearTextToImageGraph(state);

      //const params = buildTextToImageParm(state);
      dispatch(setCurrentGen('TXT2IMG'));
      dispatch(genGraph(action.payload));
      //dispatch(textToImageGraphBuilt(graph));

      // moduleLog.debug({ data: graph }, 'Text to Image graph built');

      // dispatch(sessionCreated({ graph }));

      // await take(sessionCreated.fulfilled.match);

      dispatch(sessionReadyToInvoke());
    },
  });
};
