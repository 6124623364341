import {
  Client4 as ClientClass4,
  DEFAULT_LIMIT_AFTER,
  DEFAULT_LIMIT_BEFORE,
} from 'aldgpt_design/app/client';

const Client4 = new ClientClass4();

export {
  Client4,
  DEFAULT_LIMIT_AFTER,
  DEFAULT_LIMIT_BEFORE,
};