import { startAppListening } from '..';
// import { sessionCreated } from 'aldgpt_design/services/api/thunks/session';
import { buildNodesGraph } from 'aldgpt_design/features/nodes/util/graphBuilders/buildNodesGraph';
import { log } from 'aldgpt_design/app/logging/useLogger';
import { nodesGraphBuilt } from 'aldgpt_design/features/nodes/store/actions';
import { userInvoked } from 'aldgpt_design/app/store/actions';
import { sessionReadyToInvoke } from 'aldgpt_design/features/system/store/actions';

const moduleLog = log.child({ namespace: 'invoke' });

export const addUserInvokedNodesListener = () => {
  //axel
  // startAppListening({
  //   predicate: (action): action is ReturnType<typeof userInvoked> =>
  //     userInvoked.match(action) && action.payload === 'nodes',
  //   effect: async (action, { getState, dispatch, take }) => {
  //     const state = getState();

  //     const graph = buildNodesGraph(state);
  //     dispatch(nodesGraphBuilt(graph));
  //     moduleLog.debug({ data: graph }, 'Nodes graph built');

  //     dispatch(sessionCreated({ graph }));

  //     await take(sessionCreated.fulfilled.match);

  //     dispatch(sessionReadyToInvoke());
  //   },
  // });
};
