import { canvasSavedToGallery } from 'aldgpt_design/features/canvas/store/actions';
import { startAppListening } from '..';
import { log } from 'aldgpt_design/app/logging/useLogger';
import { imageUploaded } from 'aldgpt_design/services/api/thunks/image';
import { getBaseLayerBlob } from 'aldgpt_design/features/canvas/util/getBaseLayerBlob';
import { addToast } from 'aldgpt_design/features/system/store/systemSlice';
import { imageUpserted } from 'aldgpt_design/features/gallery/store/imagesSlice';

const moduleLog = log.child({ namespace: 'canvasSavedToGalleryListener' });

export const addCanvasSavedToGalleryListener = () => {
  startAppListening({
    actionCreator: canvasSavedToGallery,
    effect: async (action, { dispatch, getState, take }) => {
      const state = getState();

      const blob = await getBaseLayerBlob(state);

      if (!blob) {
        moduleLog.error('Problem getting base layer blob');
        dispatch(
          addToast({
            title: 'Problem Saving Canvas',
            description: 'Unable to export base layer',
            status: 'error',
          })
        );
        return;
      }

      const imageUploadedRequest = (dispatch as any)(
        imageUploaded({
          file: new File([blob], 'savedCanvas.png', {
            type: 'image/png',
          }),
          image_category: 'general',
          is_intermediate: false,
          postUploadAction: {
            type: 'TOAST_CANVAS_SAVED_TO_GALLERY',
          },
        })
      );

      const [{ payload: uploadedImageDTO }] = await take(
        (
          uploadedImageAction
        ): uploadedImageAction is ReturnType<typeof imageUploaded.fulfilled> =>
          imageUploaded.fulfilled.match(uploadedImageAction) &&
          uploadedImageAction.meta.requestId === imageUploadedRequest.requestId
      );

      dispatch(imageUpserted(uploadedImageDTO as any));
    },
  });
};
