import { startAppListening } from '..';
import { imageUploaded } from 'aldgpt_design/services/api/thunks/image';
import { addToast } from 'aldgpt_design/features/system/store/systemSlice';
import { log } from 'aldgpt_design/app/logging/useLogger';
import { imageUpserted, imageUpsertMany } from 'aldgpt_design/features/gallery/store/imagesSlice';
import { setInitialCanvasImage } from 'aldgpt_design/features/canvas/store/canvasSlice';
import { controlNetImageChanged } from 'aldgpt_design/features/controlNet/store/controlNetSlice';
import { initialImageChanged } from 'aldgpt_design/features/parameters/store/generationSlice';
import { fieldValueChanged } from 'aldgpt_design/features/nodes/store/nodesSlice';
import { isEnabledToggled, isStructureEnabledToggled, setStructureEnabled, structureImageChanged,styleImageChanged } from 'aldgpt_design/features/advanced/store/slice'
import { Client4 } from 'aldgpt_design/common/util/client';


const moduleLog = log.child({ namespace: 'image' });

export const addImageUploadedFulfilledListener = () => {
  startAppListening({
    actionCreator: imageUploaded.fulfilled,
    effect: (action, { dispatch, getState }) => {
      const image = action.payload;

      // if (action.payload.is_intermediate) {
      //   // No further actions needed for intermediate images
      //   return;
      // }
      dispatch(imageUpserted(image));

      const { postUploadAction } = action.meta.arg;

      if (postUploadAction?.type === 'TOAST_CANVAS_SAVED_TO_GALLERY') {
        dispatch(
          addToast({ title: 'Canvas Saved to Gallery', status: 'success' })
        );
        return;
      }

      if (postUploadAction?.type === 'TOAST_CANVAS_MERGED') {
        dispatch(addToast({ title: '合并', status: 'success' }));
        return;
      }

      if (postUploadAction?.type === 'SET_CANVAS_INITIAL_IMAGE') {
        dispatch(setInitialCanvasImage(image));
        return;
      }

      if (postUploadAction?.type === 'SET_CONTROLNET_IMAGE') {
        const { controlNetId } = postUploadAction;
        dispatch(
          controlNetImageChanged({
            controlNetId,
            controlImage: image.image_name,
          })
        );
        return;
      }

      if (postUploadAction?.type === 'SET_INITIAL_IMAGE') {
        dispatch(initialImageChanged(image));
        return;
      }

      if (postUploadAction?.type === 'SET_NODES_IMAGE') {
        const { nodeId, fieldName } = postUploadAction;
        dispatch(fieldValueChanged({ nodeId, fieldName, value: image }));
        return;
      }

      if (postUploadAction?.type === 'TOAST_UPLOADED') {
        dispatch(addToast({ title: '图片已经上传', status: 'success' }));
        return;
      }

      if (postUploadAction?.type === 'SET_STRUCTURE_IMAGE') {
        dispatch(structureImageChanged({ structureImage: image.image_name}));
        dispatch(setStructureEnabled(true));
        return;
      }

      if (postUploadAction?.type === 'SET_STYLE_IMAGE') {
        dispatch(styleImageChanged({styleImage: image.image_name}));
        return;
      }
    },
  });
};

export const addImageUploadedRejectedListener = () => {
  startAppListening({
    actionCreator: imageUploaded.rejected,
    effect: (action, { dispatch }) => {
      // const { formData, ...rest } = action.meta.arg;
      // const sanitizedData = { arg: { ...rest, formData: { file: '<Blob>' } } };
      // moduleLog.error({ data: sanitizedData }, 'Image upload failed');
      // dispatch(
      //   addToast({
      //     title: 'Image Upload Failed',
      //     description: action.error.message,
      //     status: 'error',
      //   })
      // );
    },
  });
};
